@import url("https://fonts.googleapis.com/css2?family=Newsreader:opsz,wght@6..72,300&display=swap");

:root {
  --primary: #a09e9d;
  --secondary: #15ace1;
  --light: #f4f7fe;
  --dark: #000000;
}

* {
  font-family: "Newsreader", serif !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2 {
  font-weight: 600 !important;
}

h5 {
  font-weight: 500 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bg-primary-c {
  color: black !important;
  background-color: #dadad8 !important;
}

.bg-primary-c .nav-link {
  color: #000000 !important;
}

.bg-primary-c .nav-link:hover,
.bg-primary-c .nav-link.active {
  color: #990011 !important;
}

.nav-link {
  cursor: pointer !important;
  margin-right: 35px !important;
  padding: 0 !important;
  color: rgba(255, 255, 255, 0.8) !important;
  outline: none !important;
  font-weight: 700 !important;
}

.nav-link:hover,
.nav-link.active {
  color: #990011 !important;
}

.navbar {
  padding: 11px 0 !important;
  height: 75px;
}

.sticky-top {
  top: -150px;
  transition: 0.5s;
}

.nav {
  height: 70px !important;
}

.bg-transparent {
  background-color: rgba(255, 0, 0, 0) !important;
}

.hero-header {
  background-color: var(--primary) !important;
  margin-top: -75px;
  background: url("../public/assets/images/bg-hero.png") center center no-repeat;
  background-size: cover;
  /* padding-top: 100px !important; */
}

@media (max-width: 992px) {
  .home-shirt,
  .why-choose {
    padding-top: 50px;
  }
}

@media (min-width: 992px) {
  .wts {
    display: none !important;
  }
}

/*** Service ***/
.service-item {
  position: relative;
  padding: 45px 30px;
  background: #ffffff;
  transition: 0.5s;
}

.service-item:hover {
  background: var(--primary);
}

.service-item .service-icon {
  margin: 0 auto 20px auto;
  width: 90px;
  height: 90px;
  color: var(--primary);
  background: #a09e9d26;
  transition: 0.5s;
}

.service-item:hover .service-icon {
  background: #ffffff;
}

.service-item h5,
.service-item p {
  transition: 0.5s;
}

.service-item:hover h5,
.service-item:hover p {
  color: #ffffff;
}

.service-item a.btn {
  position: relative;
  display: flex;
  color: var(--primary);
  transition: 0.5s;
  z-index: 1;
}

.service-item:hover a.btn {
  color: var(--primary);
}

.service-item a.btn::before {
  position: absolute;
  content: "";
  width: 35px;
  height: 35px;
  top: 0;
  left: 0;
  border-radius: 35px;
  background: var(--light);
  transition: 0.5s;
  z-index: -1;
}

.service-item:hover a.btn::before {
  width: 100%;
  background: var(--light);
}

.btn-square {
  width: 38px;
  height: 38px;
}
.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  font-weight: normal;
  border-radius: 50px;
}

.icon {
  padding-top: 12px;
}

.values {
  background-color: #a09e9d26 !important;
}

.fa-icon {
  color: #555555;
}

** Testimonial ** .testimonial-carousel {
  position: relative;
}

.testimonial-carousel .owl-nav {
  position: absolute;
  width: 40px;
  height: 100%;
  top: calc(50% - 50px);
  left: -21px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next {
  margin: 5px 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary);
  background: #ffffff !important;
  border: 1px solid var(--primary) !important;
  border-radius: 40px;
  font-size: 18px;
  transition: 0.5s;
}

.testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial-carousel .owl-nav .owl-next:hover {
  background: var(--primary) !important;
  color: #ffffff !important;
}

.testimonial-carousel .owl-dots {
  margin-top: 35px;
  margin-left: 3rem;
}

.testimonial-carousel .owl-dot {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  width: 15px;
  height: 15px;
  background: #ffffff !important;
  border: 1px solid var(--primary) !important;
  border-radius: 15px;
  transition: 0.5s;
}

.testimonial-carousel .owl-dot.active {
  width: 30px;
  background: var(--primary) !important;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  font-size: 34px !important;
}

/*** FAQs ***/
.accordion .accordion-item {
  border: none;
  margin-bottom: 15px;
}

.accordion .accordion-button {
  background: #a09e9d26;
  border-radius: 5px !important;
}

.accordion .accordion-button:not(.collapsed) {
  color: #ffffff;
  background: var(--primary);
  box-shadow: none;
}

.accordion .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion .accordion-body {
  padding: 15px 0 0 0;
}

.accordion-button:focus {
  box-shadow: 0 0 0 0.25rem rgb(218 218 216) !important;
}

.form-control:focus {
  border-color: #dadad8 !important;
  box-shadow: 0 0 0 0.25rem rgb(218 218 216) !important;
}

.form-control {
  border-radius: 10px !important;
}

.num-input {
  height: calc(3.5rem + 2px) !important;
}

.react-tel-input .form-control {
  width: 100% !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  height: 58px !important;
}

.sent-message {
  text-align: center;
  padding: 15px;
  border-radius: 10px;
}

.loading {
  text-align: center;
  padding: 15px;
  border-radius: 10px;
  color: white;
  background-color: #a09e9d;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid var(--primary);
  border-top-color: #eee;
  animation: animate-loading 1s linear infinite;
}

/*** Footer ***/
.footer {
  background: url("../public/assets/images/footer.png") center center no-repeat;
  background-size: contain;
}

.footer .btn.btn-social {
  margin-right: 5px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(256, 256, 256, 0.1);
  border-radius: 40px;
  transition: 0.3s;
}

.footer .btn.btn-social:hover {
  color: var(--primary);
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 10px;
  padding: 0;
  text-align: left;
  color: rgba(255, 255, 255, 0.5);
  font-weight: normal;
  transition: 0.3s;
}

.footer .btn.btn-link:hover {
  color: #ffffff;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  letter-spacing: 1px;
  box-shadow: none;
}

.footer .copyright {
  padding: 25px 0;
  font-size: 14px;
  border-top: 1px solid rgba(256, 256, 256, 0.1);
}

.footer .copyright a {
  color: rgba(255, 255, 255, 0.5);
}

.footer .copyright a:hover {
  color: #ffffff;
}

.footer .footer-menu a {
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.footer .footer-menu a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}

.copyright {
  padding: 25px 0;
  font-size: 14px;
  border-top: 1px solid rgba(256, 256, 256, 0.1);
}

.copyright a {
  color: rgba(255, 255, 255, 0.5);
}

.copyright a:hover {
  color: #ffffff;
}

.links-under {
  text-decoration: none !important;
}

@media (max-width: 991.98px) {
  .bg-primary-c .nav-link {
    color: white !important;
  }
  .navbar .navbar-nav {
    margin-top: 5px !important;
    padding: 0 15px !important;
    background: #5b5857 !important;
    border-radius: 5px !important;
  }

  .navbar .navbar-nav .nav-link {
    margin-right: 0;
    padding: 15px 0 !important;
  }
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 80px;
  right: 10px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.float:hover {
  color: white;
}

.my-float {
  margin-top: 16px;
}
