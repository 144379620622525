.scroll-to-top-button {
  position: fixed;
  z-index: 9999;
  bottom: 20px;
  left: 20px;
  background-color: rgba(160, 158, 157);
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  box-shadow: -2px 0px 16px -4px #808080;
}
